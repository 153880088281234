import styled from 'styled-components'

import Accordion from './../Accordion';

const StyledCampaign = styled.article`
  /* background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: white;
  margin: 0.5em 1em;
  padding: 0.25em 1em;
  width: 90%; */
`;

const  Campaign = (props) => {
  console.log("campaign budget", props.budget);
  
  return (
    <StyledCampaign 
      className="
        campaign
      ">
       <Accordion
        name={props.name}
        budget={props.budget}
        status={props.status}
        bid={props.bid}
        since={props.since}
        clicks={props.clicks}
        cpc={props.cpc}
        ctr={props.ctr}
        impressions={props.impressions}
        cpm={props.cpm}
        reach={props.reach}
        frequency={props.frequency}
        amount_spend={props.amount_spend}
        conversions={props.conversions}
        cpa={props.cpa}
      />
    </StyledCampaign>
  );
}

export default Campaign;