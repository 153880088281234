import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: ${(props) => { 
    // console.log(props)
    return props.alert ? "palevioletred" : "#ade021"
  }};
  border-radius: 2rem;
  border: 2px solid palevioletred;
  color: #330033;
  margin: 0.5em 0em 0.5rem;
  padding: 0.5em 1em;
  width: calc(100%);
  text-transform: uppercase;
  font-weight: 900;
`;

const  Button = (props) => {
  return (
    <StyledButton 
      className="button"
      alert={props.alert}
    >
      {props.name}
    </StyledButton>
  );
}

export default Button;
