import styled from 'styled-components'

const StyledFooter = styled.footer`
  min-height: 10vh;
  background-color: rgba(0,0,255,0);
`

const  Footer = () => {
  return (
    <StyledFooter 
      className="
        footer 
        flex 
        items-center 
        justify-center 
        sm:text-sm 
        md:text-base 
        lg:text-3xl 
        text-white
        font-black
        uppercase
      ">
      ADDSOME POC &#169; Konversiotoimisto Kumpula Oy
    </StyledFooter>
  );
}

export default Footer;
