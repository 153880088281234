import styled from 'styled-components'
import React, { useEffect, useState } from 'react';
import { getCampaigns } from '../../services/campaigns';
import Logo from './../../assets/images/logo.png';
import Campaigns from './../../components/Campaigns';
import AddCampaign from '../../components/AddCampaign';
// import Plus from './../../components/Plus';

const StyledMain = styled.main`
  min-height: 90vh;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content:center;
  padding-top: 10vw;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 250px;
  }
  ul { padding: 0; }
  li { list-style: none; }
`

const StyledLogo = styled.img`
  width: 60%;
  max-width: 80%;
  max-height: 60vh;
  margin: 1rem auto;
`

function Main() {
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    let mounted = true;
    getCampaigns()
      .then(campaigns => {
        if(mounted) {
          setCampaigns(campaigns);
          console.log("campaigns", campaigns);
        }
      })
    return () => mounted = false;
  }, [])

  return (
    <StyledMain className="Main">

      <section className="flex-initial">
        <StyledLogo src={Logo} alt="logo" /> 

        {campaigns.length === 0 &&
          <p className="text-white text-center">No campaigns yet!</p>
        }
      </section>
      <AddCampaign className="flex-initial" />
      <Campaigns className="" />
    </StyledMain>
  );
}

export default Main;
