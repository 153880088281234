export function getCampaigns() {
  
  return fetch('https://adsometool.com/api/list', {
    "method": "GET",
    "headers": {
      "content-type": "application/json",
      "accept": "application/json",
      // 'Authorization': 'Basic yksi:kaksi',
    },
  })
    .then(data => data.json())
    
}

export function putCampaigns() {
  
  return fetch('https:/yksi:kaksi@/adsometool.com/api/list', {
    "method": "PUT",
    "headers": {
      "content-type": "application/json",
      "accept": "application/json",
      'Authorization': 'Basic ' + btoa('yksi:kaksi'),
    },
  })
  .then(response => response.json())
  .then(response => { console.log(response);
  })
  .catch(err => { console.log(err); });
    
}
