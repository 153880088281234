import React, { useState } from 'react';
import styled from 'styled-components'

import Plus from './../Plus';
import Form from './../Form';

import Facebook from './../../assets/images/facebook-big.png';
import Google from './../../assets/images/google-big.png';
import Twitter from './../../assets/images/twitter-big.png';
import Linkedin from './../../assets/images/linkedin-big.png';
import Instagram from './../../assets/images/instagram-big.png';
import Some from './../../assets/images/some-big.png';

const StyledAddCampaign = styled.section`
  margin: 0.5rem 1rem;
  padding: 1.25rem 1rem;
  width: calc(100% - 2rem);
  max-width: 500px; 
  background-color: transparent; 
  overflow: auto;
  text-align: center;
  transition: max-height 0.6s ease;
  .some-icons {
    display: flex;
    flex-wrap: wrap;
    .img-wrap {
      flex: 0 0 50%;
      padding: 1.5rem;
      border-radius: 50%50%;
      img {
        border-radius: 50%50%;
        transition: box-shadow 0.2s ease;
        &:hover, &:focus {
          box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
        }
      }
      &:focus {
        outline: none;  
        box-shadow: 0px 0px 10px #ffffff;
        img {
          box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
        }
      }
    }
  }
`;

const  AddCampaign = (props) => {
  const [newCampaign, setNewCampaign] = useState(false);
  const [platformSelected, setPlatformSelected] = useState();
  const [platform, setPlatform] = useState();

  return (
    <StyledAddCampaign 
      onClick={() => setNewCampaign(true)}
      className="
        add-campaign
      ">
      
      {!newCampaign && 
        <Plus name="Add New" />
      }
      {newCampaign && !platformSelected && 
        <div className="some-icons">
          <button 
            className="img-wrap"
            tabIndex="0" 
            onClick={() => {
                setPlatform("Facebook");
                setPlatformSelected(true);
              }
            }
          >
              <img src={Facebook} alt="Facebook icon" />
          </button>
          <button 
            className="img-wrap"
            tabIndex="0" 
            onClick={() => {
                setPlatform("Google");
                setPlatformSelected(true);
              }
            }
          >
              <img src={Google} alt="Google icon" />
          </button>
          <button 
            className="img-wrap opacity-60"
            // tabIndex="0" 
            // onClick={() => setPlatform("")}
          >
              <img src={Instagram} alt="Instagram icon" />
          </button>
          <button 
            className="img-wrap opacity-60"
            // tabIndex="0" 
            // onClick={() => setPlatform("")}
          >
              <img src={Twitter} alt="Twitter icon" />
          </button>
          <button 
            className="img-wrap opacity-60"
            // tabIndex="0" 
            // onClick={() => setPlatform("")}
          >
              <img src={Linkedin} alt="Linkedin icon" />
          </button>
          <button 
            className="img-wrap opacity-60"
            // tabIndex="0" 
            // onClick={() => setPlatform("")}
          >
              <img src={Some} alt="Some icon" />
          </button>
        </div>
      }
      {platformSelected && 
        <Form platform={platform} />
      }
    </StyledAddCampaign>
  );
}

export default AddCampaign;
